import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setChatID } from './chatIDModule';

const Disclaimer = ({ onIDGenerated, show }) => {
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [acceptedConditions, setAcceptedConditions] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [chatID, setChatID] = useState('');
    const [convID, setConvID] = useState('');

    useEffect(() => {
        setShowDisclaimer(show);
        setAcceptedConditions(false);
    }, [show]);

    const onClickCaptcha = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const postData = {
                key: "value" // Remplacez key et value par les données nécessaires pour votre requête
            };
            const response = await axios.post('https://temp-mr-small-getthreadid.azurewebsites.net/api/createthread?code=Rm0GwI84JGi3dss9eXj0n-WJoEEnX-LidXtj_5SbEDMJAzFuAvTD3w==', postData);
            console.log(response)
            const newChatID = response.data.ChatID; // Assurez-vous que la clé 'ChatID' est correcte selon votre API
            const newConvID = newChatID; // Supposons que c'est un ID fixe pour l'exemple

            setChatID(newChatID);
            setConvID(newConvID);
            onIDGenerated(newChatID);

            if (acceptedConditions) {
                setShowDisclaimer(false);
            } else {
                alert('Veuillez accepter les conditions.');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du ChatID:', error);
            alert('Un problème est survenu lors de la récupération des informations.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={`${showDisclaimer ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center`}>
            <div className='mx-auto bg-white p-5 rounded-xl w-11/12 sm:w-[502px] text-center shadow-xl '>
                <p className='text-xl font-semibold my-5'>Conditions d'utilisation:</p>
                <div className='text-left'>
                    <p className='text-base my-4'>
                        Merci de vous intéresser au MR. Nous utilisons l'IA pour clarifier notre programme et répondre efficacement à vos questions, soulignant notre engagement envers l'ère numérique et les bénéfices de l'IA pour la démocratie.
                    </p>
                </div>
                <div className='flex justify-center items-center my-4'>
                    <label>
                        <input
                            type='checkbox'
                            checked={acceptedConditions}
                            onChange={() => setAcceptedConditions(!acceptedConditions)}
                            className='w-5 h-5 m-2 rounded-sm accent-blue-500 forced-colors:appearance-auto'
                        />
                    </label>
                    <div className='text-xs text-left'>
                        Je suis d’accord avec les conditions générales d’utilisation
                        <br />
                        <a href='https://www.mr.be/mentions-legales/'
                            className='text-xs text-blue-600 underline'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Conditions générales d’utilisation
                        </a>
                    </div>
                </div>
                <button
                    onClick={onClickCaptcha}
                    disabled={isSubmitting} // Désactive le bouton pendant l'appel API
                    className={`${!acceptedConditions || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''} py-1 px-8 text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-300 my-3`}
                    style={{ boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)' }}>
                    J'y vais!
                </button>
            </div>
        </div>
    );
};

export default Disclaimer;

// ChatMessage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextToSpeechButton from './TextToSpeechButton';
import Cookies from 'js-cookie';
import { getChatID } from './chatIDModule';
const ChatMessage = ({
	message,
	isLastMessage,
	lastUserMessage,
	audioOn,
	selectLanguage,
	onDataFromChild,
	handleIsWriting,
	loader,
}) => {
	const [userAgreed, setUserAgreed] = useState(false);
	const [userDisagreed, setUserDisagreed] = useState(false);

	const handleAgree = async () => {
		setUserAgreed(true);
		setUserDisagreed(false);

		sendFeedback(lastUserMessage.text, 1);
	};

	const handleDisagree = async () => {
		setUserDisagreed(true);
		setUserAgreed(false);

		sendFeedback(lastUserMessage.text, 0);
	};
	const sendFeedback = async (text, binaire) => {
		try {
			await axios.post(
				'https://wrongapi.com',
				{
					chat_response: text,
					feedback: binaire,

				}
			);
		} catch (error) {
			console.error('Erreur lors de la récupération du feedback :', error);
			// Gérer les erreurs d'API
		}
	};
	const [agreeButtonText, setAgreeButtonText] = useState('I Agree');
	const [disagreeButtonText, setDisagreeButtonText] = useState('I Disagree');

	useEffect(() => {
		// Mettez en place une logique pour changer le texte des boutons en fonction de la langue sélectionnée
		switch (selectLanguage) {
			case 'en':
				setAgreeButtonText('I Agree');
				setDisagreeButtonText('I Disagree');
				
				break;
			case 'de':
				setAgreeButtonText('Ich stimme zu');
				setDisagreeButtonText('Ich stimme nicht zu');
				
				break;
			case 'nl':
				setAgreeButtonText('Ik ga akkoord');
				setDisagreeButtonText('Ik ga niet akkoord');
				
				break;
			case 'fr':
				setAgreeButtonText("Je suis d'accord");
				setDisagreeButtonText("Je ne suis pas d'accord");
				
				break;
			default:
				setAgreeButtonText("Je suis d'accord"); // La langue par défaut
				setDisagreeButtonText("Je ne suis pas d'accord"); // La langue par défaut
		}
	}, [selectLanguage]);

	const [data, setData] = useState('');
	const [loading, setLoading] = useState(loader);
const [streamingComplete, setStreamingComplete] = useState(false);

	useEffect(() => {
		const fetchData = async (response) => {
			try {
				const reader = response.body.getReader();
				const decoder = new TextDecoder();
		
				// Activez handleIsWriting ici pour indiquer le début du traitement
				handleIsWriting(true);
				
				let ongoingText = '';
				
				while (true) {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
		
					const decodedChunk = decoder.decode(value, { stream: true });
          const lines = decodedChunk.split("\n");
					
					for (const line of lines) {
            console.log(line);
            if (line.startsWith("data:")) {
							const dataJson = line.substring(5).trim();
							try {
								const dataObj = JSON.parse(dataJson);
								
								if (dataObj.delta && dataObj.delta.content) {
									dataObj.delta.content.forEach((contentItem) => {
										if (contentItem.text && contentItem.text.value) {
                      let filteredText = contentItem.text.value.replace(
                        /【.*?source】/g,
                        ""
                      );
                      filteredText = filteredText.replace(/\*/g, "");
                                    		ongoingText += filteredText;
											// ongoingText += contentItem.text.value;
										}
									});
								}
							} catch (error) {
                console.error("Failed to parse JSON:", error);
								// Gérer les erreurs spécifiques au parsing JSON ici
							}
						}
					}
          ongoingText = ongoingText.replace(/【.*?source】/g, "");
					setData(ongoingText);
					// Considérez de désactiver handleIsWriting ici si vous voulez indiquer la fin de l'écriture après chaque chunk
				}
				handleIsWriting(false);
		
				setStreamingComplete(true);
			} catch (error) {
				console.error('Error during streaming:', error);
				setLoading(false);
			} finally {
				// Désactivez handleIsWriting ici pour indiquer que le traitement est totalement terminé
				handleIsWriting(false);
				setLoading(false);
			}
		};
		if (!message.isUser && typeof message.text === 'string') {
			setData(message.text);
		} else if (!message.isUser) {
			fetchData(message.text);
		}
		setLoading(loader);
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, message.text]);

	useEffect(() => {
		if (!loading) {
			onDataFromChild(data);
		}
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

  console.log("data", data);
	return (
    <div className="text-left mx-auto w-11/12">
  <div
        className={`mx-2 my-2 ${
          isLastMessage ? "" : "border-b-2"
        } border-[#D0D3DB] relative w-full`}
  >
    {message.isUser ? (
      <img
        src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/user-3296+1.png'
        alt='Utilisateur'
        className='mx-1 my-2 absolute top-0 -left-5'
        style={{ width: '30px', height: '30px' }}
      />
    ) : (
      <img
        src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/picto-envoyer.png'
        alt=''
        className='mx-1 my-2 absolute top-0 -left-5'
        style={{ width: '30px', height: '30px' }}
					/>
				)}
				<div className='flex-col py-2 pl-6 mb-5 w-full'>
					<div className='flex w-full'>
						<span className='text-base font-bold block align-middle whitespace-nowrap'>
							{message.isUser ? 'Vous' : 'Victor IA'}
						</span>
						{message.isUser && isLastMessage
							? ''
							: isLastMessage &&
							  streamingComplete && (
									<TextToSpeechButton
										textToSpeak={data}
										isLastMessage
										audioOn={audioOn}
										selectLanguage = {selectLanguage}
																			/>
							  )}
					</div>
					<span className='text-justify max-w-prose text-sm sm:text-base font-medium' style={{ whiteSpace: 'pre-wrap' }}>
						{message.isUser ? message.text : data}
						</span>

					{/* {!message.isUser && isLastMessage && streamingComplete && (
						<div className='mt-2 text-white flex sm:block'>
							{userAgreed || userDisagreed ? (
								<button
									className={`${
										userAgreed ? 'bg-green-500' : 'bg-gray-500'
									} text-white text-xs px-8 sm:px-16 py-1 rounded-xl mr-28`}>
									{userAgreed ? agreeButtonText : disagreeButtonText}
								</button>
							) : (
								<div className='border-zinc-500 border-2 rounded-lg w-min flex'>
									<button onClick={() => handleAgree()}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											height='24px'
											viewBox='0 0 24 24'
											width='24px'
											fill='#000000'
											className='mx-2 my-1'>
											<path
												d='M0 0h24v24H0V0z'
												fill='none'
											/>
											<path d='M13.11 5.72l-.57 2.89c-.12.59.04 1.2.42 1.66.38.46.94.73 1.54.73H20v1.08L17.43 18H9.34c-.18 0-.34-.16-.34-.34V9.82l4.11-4.1M14 2L7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.83C7 18.95 8.05 20 9.34 20h8.1c.71 0 1.36-.37 1.72-.97l2.67-6.15c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2zM4 9H2v11h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1z' />
										</svg>
									</button>
									<button onClick={() => handleDisagree()}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											height='24px'
											viewBox='0 0 24 24'
											width='24px'
											fill='#000000'
											className='mx-2 my-1'>
											<path
												d='M24 24H0V0h24v24z'
												fill='none'
											/>
											<path d='M10.89 18.28l.57-2.89c.12-.59-.04-1.2-.42-1.66-.38-.46-.94-.73-1.54-.73H4v-1.08L6.57 6h8.09c.18 0 .34.16.34.34v7.84l-4.11 4.1M10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22zm10-7h2V4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1z' />
										</svg>
									</button>
								</div>
							)}
						</div>
					)} */}
				</div>
			</div>
		</div>
	);
};

export default ChatMessage;
